<template>
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.945 19.2497L22.75 13.0547L25.2175 10.5872L28.9275 14.2972L36.3475 6.8772L38.815 9.3447L28.945 19.2497ZM19.25 12.2497H3.5V15.7497H19.25V12.2497ZM36.75 23.4672L34.2825 20.9997L29.75 25.5322L25.2175 20.9997L22.75 23.4672L27.2825 27.9997L22.75 32.5322L25.2175 34.9997L29.75 30.4672L34.2825 34.9997L36.75 32.5322L32.2175 27.9997L36.75 23.4672ZM19.25 26.2497H3.5V29.7497H19.25V26.2497Z"
      fill="#2C3357"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgIconMissions'
}
</script>

<style></style>
