<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.25 0.25H14.25V17.75H0.25V0.25ZM31.75 0.25H17.75V10.75H31.75V0.25ZM10.75 14.25V3.75002H3.75V14.25H10.75ZM28.25 7.25002V3.75002H21.25V7.25002H28.25ZM28.25 17.75V28.25H21.25V17.75H28.25ZM10.75 28.25V24.75H3.75V28.25H10.75ZM31.75 14.25H17.75V31.75H31.75V14.25ZM0.25 21.25H14.25V31.75H0.25V21.25Z"
      fill="#2C3357"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgIconDashboard'
}
</script>

<style></style>
