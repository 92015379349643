<template>
  <div v-loading="loading" class="container">
    <FraudIndicators @loading="handleLoading"></FraudIndicators>
    <!-- <DashboardNav></DashboardNav>
    <TrackedDevice></TrackedDevice>-->
  </div>
</template>

<script>
import DashboardNav from '@/components/dashboard/dashboard-nav'
import FraudIndicators from '@/components/dashboard/fraud/indicators'
import TrackedDevice from '@/components/dashboard/tracked-device'
export default {
  name: 'Dashbaord',
  data() {
    return {
      loading: false
    }
  },
  components: {
    FraudIndicators
  },
  methods: {
    handleLoading(val) {
      this.loading = val
    }
  },
  mounted() {
    this.loading = true
  }
}
</script>

<style lang="scss" scoped>
.container {
  min-height: 85vh;
  //   min-height: 100%;
  margin: 27px 38px 12px 32px;
}
</style>
