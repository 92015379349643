<template>
  <svg
    width="24"
    height="14"
    viewBox="0 0 24 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.83 0L11.25 10.58L7.07 6.41L5.66 7.82L11.25 13.41L23.25 1.41L21.83 0ZM17.59 1.41L16.18 0L9.84 6.34L11.25 7.75L17.59 1.41ZM5.59 13.41L0 7.82L1.42 6.41L7 12L5.59 13.41Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgIconNotification'
}
</script>

<style></style>
