import { render, staticRenderFns } from "./premise-devices.vue?vue&type=template&id=080a90d6&scoped=true&"
import script from "./premise-devices.vue?vue&type=script&lang=js&"
export * from "./premise-devices.vue?vue&type=script&lang=js&"
import style0 from "./premise-devices.vue?vue&type=style&index=0&id=080a90d6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "080a90d6",
  null
  
)

export default component.exports