<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="alert/warning_amber_24px">
      <path
        id="icon/outlined/alert/warning_amber_24px"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1 21.5L12 2.5L23 21.5H1ZM19.53 19.5L12 6.49L4.47 19.5H19.53ZM11 16.5V18.5H13V16.5H11ZM11 10.5H13V14.5H11V10.5Z"
        :fill="changeColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SvgIconSabotage',
  props: {
    status: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    changeColor() {
      if (this.status == 1) {
        return '#6FCF97'
      } else {
        if (this.status == 2) return '#EB5757'
        else return '#B7B7B7'
      }
    }
  }
}
</script>

<style></style>
