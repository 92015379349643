<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="offline_bolt_24px">
      <path
        id="icon/action/offline_bolt_24px"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 2.02002C6.49002 2.02002 2.02002 6.49002 2.02002 12C2.02002 17.51 6.49002 21.98 12 21.98C17.51 21.98 21.98 17.51 21.98 12C21.98 6.49002 17.51 2.02002 12 2.02002ZM12 19.98C7.60002 19.98 4.02002 16.4 4.02002 12C4.02002 7.60002 7.60002 4.02002 12 4.02002C16.4 4.02002 19.98 7.60002 19.98 12C19.98 16.4 16.4 19.98 12 19.98ZM8.25002 13.5L12.75 5.00002V10.5H15.75L11.39 19V13.5H8.25002Z"
        :fill="changeColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SvgIconEnergy',
  props: {
    status: { type: Boolean }
  },
  computed: {
    changeColor() {
      if (this.status == 1) {
        return '#6FCF97'
      } else {
        if (this.status == 0) return '#B7B7B7'
        else return '#EB5757'
      }
    }
  }
}
</script>

<style></style>
