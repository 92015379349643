<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 7C10.136 7 7 10.136 7 14C7 17.864 10.136 21 14 21C17.864 21 21 17.8639 21 13.9999C21 10.136 17.864 7 14 7Z"
      :fill="changeColor"
    />
    <path
      d="M14 0C6.26502 0 0 6.26502 0 14C0 21.7349 6.26502 27.9999 14 27.9999C21.7349 27.9999 28 21.735 28 14C28 6.26495 21.735 0 14 0ZM14 25.2C7.81199 25.2 2.79995 20.188 2.79995 14C2.79995 7.81193 7.81199 2.80002 14 2.80002C20.1879 2.80002 25.2 7.81199 25.2 14C25.2 20.1879 20.188 25.2 14 25.2Z"
      :fill="changeColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgIconRecords',
  props: {
    status: { type: Boolean, default: null }
  },
  computed: {
    changeColor() {
      if (this.status) {
        return '#6FCF97'
      } else {
        if (this.status === null) return '#DADADA'
        else return '#EB5757'
      }
    }
  }
}
</script>

<style></style>
a
