<template>
  <svg
    v-if="device_id == 1"
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5343 0.852051H3.20103C1.91182 0.852051 0.867676 1.91091 0.867676 3.21827V19.7817C0.867676 21.089 1.91182 22.1478 3.20103 22.1478H19.5344C20.8235 22.1478 21.8677 21.089 21.8677 19.7816V3.21827C21.8677 1.91091 20.8235 0.852051 19.5343 0.852051ZM13.701 17.4154H11.3677V7.95064H9.03432V5.58443H13.701V17.4154H13.701Z"
      :fill="changeColor"
    />
  </svg>
  <svg
    v-else-if="device_id == 2"
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9232 0.5H2.81214C1.46155 0.5 0.367676 1.59387 0.367676 2.94446V20.0556C0.367676 21.4061 1.46155 22.5 2.81214 22.5H19.9233C21.2738 22.5 22.3677 21.4061 22.3677 20.0555V2.94446C22.3677 1.59387 21.2738 0.5 19.9232 0.5ZM15.0343 10.2778C15.0343 11.6283 13.9405 12.7223 12.5899 12.7223H10.1454V15.1667H15.0343V17.6111H7.70101V12.7223C7.70101 11.3717 8.79488 10.2778 10.1455 10.2778H12.5899V7.83333H7.70101V5.38887H12.5899C13.9405 5.38887 15.0343 6.4828 15.0343 7.83333V10.2778Z"
      :fill="changeColor"
    />
  </svg>
  <svg
    v-else-if="device_id == 3"
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9232 0.5H2.81214C1.46155 0.5 0.367676 1.59387 0.367676 2.94446V20.0556C0.367676 21.4061 1.46155 22.5 2.81214 22.5H19.9233C21.2738 22.5 22.3677 21.4061 22.3677 20.0555V2.94446C22.3677 1.59387 21.2738 0.5 19.9232 0.5ZM15.0343 9.66667C15.0343 10.6811 14.2155 11.5 13.201 11.5C14.2155 11.5 15.0343 12.3189 15.0343 13.3333V15.1667C15.0343 16.5172 13.9405 17.6111 12.5899 17.6111H7.70101V15.1667H12.5899V12.7222H10.1454V10.2777H12.5899V7.83333H7.70101V5.38887H12.5899C13.9405 5.38887 15.0343 6.4828 15.0343 7.83333V9.66667Z"
      :fill="changeColor"
    />
  </svg>

  <svg
    v-else-if="device_id == 4"
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.0555 0.5H2.94446C1.59387 0.5 0.5 1.59387 0.5 2.94446V20.0556C0.5 21.4061 1.59387 22.5 2.94446 22.5H20.0556C21.4061 22.5 22.5 21.4061 22.5 20.0555V2.94446C22.5 1.59387 21.4061 0.5 20.0555 0.5ZM15.1667 17.6111H12.7222V12.7223H7.83333V5.38893H10.2778V10.2778H12.7223V5.38887H15.1667V17.6111Z"
      :fill="changeColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgIconFirstChannel',
  props: {
    status: { type: Boolean, default: null },
    device_id: { default: 1 }
  },
  computed: {
    changeColor() {
      if (this.status) {
        return '#6FCF97'
      } else {
        if (this.status === null) return '#DADADA'
        else return '#EB5757'
      }
    }
  },
  mounted() {
    console.log('Device ID', this.device_id)
  }
}
</script>

<style></style>
