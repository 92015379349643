<template>
  <svg
    width="39"
    height="39"
    viewBox="0 0 39 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.1263 11.7488L32.1425 11.7325L26.0975 5.6875L24.375 7.41L27.8038 10.8387C26.2763 11.4237 25.1875 12.8862 25.1875 14.625C25.1875 16.8675 27.0075 18.6875 29.25 18.6875C29.835 18.6875 30.3713 18.5575 30.875 18.3462V30.0625C30.875 30.9562 30.1437 31.6875 29.25 31.6875C28.3563 31.6875 27.625 30.9562 27.625 30.0625V22.75C27.625 20.9625 26.1625 19.5 24.375 19.5H22.75V8.125C22.75 6.3375 21.2875 4.875 19.5 4.875H9.75C7.9625 4.875 6.5 6.3375 6.5 8.125V34.125H22.75V21.9375H25.1875V30.0625C25.1875 32.305 27.0075 34.125 29.25 34.125C31.4925 34.125 33.3125 32.305 33.3125 30.0625V14.625C33.3125 13.5037 32.8575 12.48 32.1263 11.7488ZM19.5 21.9375V30.875H9.75V19.5H19.5V21.9375ZM19.5 16.25H9.75V8.125H19.5V16.25ZM29.25 16.25C28.3563 16.25 27.625 15.5188 27.625 14.625C27.625 13.7312 28.3563 13 29.25 13C30.1437 13 30.875 13.7312 30.875 14.625C30.875 15.5188 30.1437 16.25 29.25 16.25Z"
      fill="#2C3357"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgIconStation'
}
</script>

<style></style>
