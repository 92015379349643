<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.5 5.5H5.5V9.5H3.5V5.5C3.5 4.4 4.4 3.5 5.5 3.5H9.5V5.5ZM3.5 15.5H5.5V19.5H9.5V21.5H5.5C4.4 21.5 3.5 20.6 3.5 19.5V15.5ZM12.5 8.5C10.29 8.5 8.5 10.29 8.5 12.5C8.5 14.71 10.29 16.5 12.5 16.5C14.71 16.5 16.5 14.71 16.5 12.5C16.5 10.29 14.71 8.5 12.5 8.5ZM10.5 12.5C10.5 13.6 11.4 14.5 12.5 14.5C13.6 14.5 14.5 13.6 14.5 12.5C14.5 11.4 13.6 10.5 12.5 10.5C11.4 10.5 10.5 11.4 10.5 12.5ZM19.5 3.5H15.5V5.5H19.5V9.5H21.5V5.5C21.5 4.4 20.6 3.5 19.5 3.5ZM15.5 19.5H19.5V15.5H21.5V19.5C21.5 20.6 20.6 21.5 19.5 21.5H15.5V19.5Z"
      fill="#444444"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgIconInterface'
}
</script>

<style></style>
