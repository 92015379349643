<template>
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.125 5.625H5.625V10.125H3.375V5.625C3.375 4.3875 4.3875 3.375 5.625 3.375H10.125V5.625ZM3.375 16.875H5.625V21.375H10.125V23.625H5.625C4.3875 23.625 3.375 22.6125 3.375 21.375V16.875ZM13.5 9C11.0138 9 9 11.0138 9 13.5C9 15.9862 11.0138 18 13.5 18C15.9862 18 18 15.9862 18 13.5C18 11.0138 15.9862 9 13.5 9ZM11.25 13.5C11.25 14.7375 12.2625 15.75 13.5 15.75C14.7375 15.75 15.75 14.7375 15.75 13.5C15.75 12.2625 14.7375 11.25 13.5 11.25C12.2625 11.25 11.25 12.2625 11.25 13.5ZM21.375 3.375H16.875V5.625H21.375V10.125H23.625V5.625C23.625 4.3875 22.6125 3.375 21.375 3.375ZM16.875 21.375H21.375V16.875H23.625V21.375C23.625 22.6125 22.6125 23.625 21.375 23.625H16.875V21.375Z"
      fill="black"
      fill-opacity="0.54"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgIconDeviceInterface'
}
</script>

<style></style>
