<template>
  <div class="container">
    <MissionFilter
      class="top"
      @onActionClick="handleActionClick"
      @onFilteredData="handleFilteredData"
    ></MissionFilter>
    <DataTable
      class="data-layer"
      :data="table_data"
      :loading="loading"
    ></DataTable>
    <DataTablePagination
      class="bottom"
      @onChangeCurrentPage="handleChangePagination"
      @onChangeSize="handleChangePagination"
    ></DataTablePagination>
    <SentinelPopup
      @onClose="handlePopupClose"
      :dialogTableVisible="popupVisible"
      :data="selectedRowData"
    >
    </SentinelPopup>
  </div>
</template>

<script>
import DataTable from '@/components/atomic/data-table.vue'
import DataTablePagination from '@/components/atomic/data-table-pagination.vue'
import MissionFilter from '@/components/missions/missions-filter.vue'
import SentinelPopup from '@/components/popup/sentinel-popup'
import axios from 'axios'
import endpoints from '@/endpoints'
import moment from 'moment'
import { bus } from '@/main.js'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Missions',
  components: {
    MissionFilter,
    DataTable,
    DataTablePagination,
    SentinelPopup
  },
  data() {
    return {
      modal_type: null,
      selectedAction: '',
      modal_visible: false,
      table_data: [],
      popupVisible: false,
      filterData: {},
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      getCurrentPage: 'pagination/getCurrentPage',
      getCurrentLimit: 'pagination/getCurrentLimit'
    }),
    getSelectedRows() {
      return this.$store.state.dataTable.selectedRows
    }
  },
  methods: {
    ...mapActions({
      refreshVguardDeviceData: 'device/refreshVguardDeviceData',
      getShopies: 'shopies/getShopies',
      setTotalRecord: 'pagination/setTotalRecord',
      setCurrentPage: 'pagination/setCurrentPage',
      setCurrentLimit: 'pagination/setCurrentLimit'
    }),
    openReceiptPopop(val) {
      console.log('openReceiptPopop Tetiklendi ::::::', val)
      this.selectedRowData = val
      this.popupVisible = true
    },
    handlePopupClose() {
      console.log('Close Tetiklendi ::::::', this.popupVisible)
      //   this.$forceUpdate()
      this.popupVisible = false
      setTimeout(() => {
        this.handleChangePagination()
        // this.fillDataTable({ offset: 0, limit: 20 })
      }, 100)
    },
    handleChangePagination() {
      console.log('handleChangePagination', this.filterData)
      this.fillDataTable({
        ...this.filterData,
        skip: (this.getCurrentPage - 1) * this.getCurrentLimit,
        limit: this.getCurrentLimit
      })
    },
    handleActionClick(val) {
      this.modal_visible = true
      switch (val) {
        case 'action':
          this.modal_type = 'action'
          break
        case 'report':
          this.modal_type = 'report'
          break
        case 'service':
          this.modal_type = 'service'
          break
      }
    },
    async handleFilteredData(val) {
      console.log('LİSt', val)
      this.filterData = val
      await this.fillDataTable({
        ...val,
        skip: (this.getCurrentPage - 1) * this.getCurrentLimit,
        limit:
          (this.getCurrentPage - 1) * this.getCurrentLimit +
          this.getCurrentLimit
      })
    },
    handleModalClose(val) {
      this.modal_visible = val
    },
    async fillDataTable(params) {
      this.filterData = { ...this.filterData, ...params }
      console.log('Gelen Datalar', params)
      let filter = {
        skip: params.skip,
        limit: params.limit,
        order: 'date DESC',
        include: ['cashier', 'store'],
        where: {
          date: {
            between: ['2022-03-13T20:55:03.187Z', '2022-03-17T20:55:03.187Z']
          }
        }
      }

      filter.order = this.filterData.order ? this.filterData.order : 'date DESC'

      if (params.date?.length > 0) {
        filter.where.date.between[0] = moment(params.date[0]).toISOString()
        filter.where.date.between[1] = moment(params.date[1])
          .add(1, 'd')
          .add(3, 'h')
          .toISOString()
      } else delete filter.where.date
      if (params.order) {
        filter.order = params.order
      }
      if (params.userCode) {
        filter.where.userCode = params.userCode
      }
      if (params.keywordsearch) {
        filter.where.keywordsearch = params.keywordsearch
      }
      let payload = {
        filter: { ...filter, where: { ...filter.where, ...params.where } }
      }
      let shopies =
        //   await axios
        //     .get('http://3.70.144.38:3000//register-activities', {
        this.$fraud({
          ...endpoints.getFraudRegisterActivity,
          params: {
            ...payload
          }
        }).then((r) => {
          this.table_data = r.data.data.result
          this.setTotalRecord(r.data.data.totalCount)
          this.setCurrentPage(params.offset + 1)
          //   this.setCurrentLimit(20)
        })
    },
    async handlePriceFilter(val) {
      console.log('handlePriceFilter', val)

      val.order == 'ascending'
        ? (this.filterData.order = 'totalPrice ASC')
        : val.order == 'descending'
        ? (this.filterData.order = 'totalPrice DESC')
        : (this.filterData.order = 'date DESC')
      await this.fillDataTable(this.filterData)
    }
  },
  created() {
    this.fillDataTable({ skip: 0, limit: this.getCurrentLimit })
    console.log('Missions Main')
  },
  mounted() {
    bus.$on('onSelectedDevicesRefresh', this.refreshVguardDeviceAndData)
    bus.$on('onServiceSorting', (val) => this.fillDataTable(val))
    bus.$on('storeOpenModal', (val) => this.openReceiptPopop(val))
    bus.$on('handlePriceFilter', (val) => this.handlePriceFilter(val))
  },
  destroyed() {
    bus.$off('onSelectedDevicesRefresh')
    bus.$off('onServiceSorting')
    bus.$off('onServiceSorting')
    bus.$off('storeOpenModal')
    bus.$off('handlePriceFilter')
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex !important;
  flex-direction: column;
  min-height: 100vh;
}
.data-layer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  // min-width: 100% !important;
  margin-left: 12px;
}
.bottom {
  padding: 34px;
  min-height: 25px;
  background: rgba(160, 160, 160, 0.1);
}
</style>
