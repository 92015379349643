var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.fullScreenLoading),expression:"fullScreenLoading"}],ref:"dashboard-content",staticClass:"fraud-indicator-content"},[_c('div',{staticClass:"left-sidebar"},[_c('div',{staticClass:"store-count"},[_c('div',{staticClass:"ellipse"},[_c('SvgIconStore')],1),_c('div',{staticClass:"info"},[_c('span',{staticClass:"label"},[_vm._v("Mağaza Sayısı")]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.dashboardData.storeCount))])])]),_c('div',{staticClass:"casier-count"},[_c('div',{staticClass:"ellipse"},[_c('SvgIconCase')],1),_c('div',{staticClass:"info"},[_c('span',{staticClass:"label"},[_vm._v("Kasa Sayısı")]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.dashboardData.cashCount))])])]),_c('div',{staticClass:"fraud-percentage"},[_c('span',{staticClass:"title"},[_vm._v("En Yüksek Kayıp/Kaçak Oranları")]),_vm._l(([].concat( _vm.createDashboardData() )),function(store){return _c('div',{key:store.id,staticClass:"row"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(store[0].split('/')[0])),_c('br'),_vm._v(_vm._s(store[0].split('/')[1]))]),_c('el-progress',{staticStyle:{"width":"185px"},attrs:{"format":{ color: '#ffd700', backgroundColor: '#ffd700' },"color":'#E04141',"text-inside":true,"stroke-width":33,"percentage":_vm.calculatePertenge(store[1])}})],1)})],2)]),_c('div',{staticClass:"center"},[_c('div',{staticClass:"title"},[_vm._v("İşlem Detayları")]),_c('div',{staticClass:"charts"},[_c('el-progress',{staticClass:"first-circular",attrs:{"type":"circle","percentage":(_vm.dashboardData.totalFraudCounts.modelSuspectCount /
            _vm.dashboardData.totalFraudCounts.totalActivityCount) *
          100,"width":"346","show-text":false,"stroke-width":12,"color":'#C4C4C4'}}),_c('el-progress',{staticClass:"second-circular",attrs:{"type":"circle","width":"294","percentage":(_vm.dashboardData.totalFraudCounts.annotatedCount /
            _vm.dashboardData.totalFraudCounts.totalActivityCount) *
          100,"status":"success","show-text":false,"stroke-width":12}}),_c('el-progress',{staticClass:"third-circular",attrs:{"type":"circle","width":"242","percentage":(_vm.dashboardData.totalFraudCounts.annotatedFraudCount /
            _vm.dashboardData.totalFraudCounts.totalActivityCount) *
          100,"status":"exception","show-text":false,"stroke-width":12}})],1),_c('div',{staticClass:"percentage"},[_c('div',{staticClass:"ellipse"},[_c('SvgIconFraud')],1),_c('span',[_vm._v("KAÇAK ORANI / TOPLAM İŞLEM")]),_c('p',[_vm._v(" %"+_vm._s(( (_vm.dashboardData.totalFraudCounts.annotatedFraudCount / _vm.dashboardData.totalFraudCounts.totalActivityCount) * 100 ).toFixed(2))+" ")])]),_c('div',{staticClass:"information"},[_c('div',{staticClass:"col"},[_c('span',{staticClass:"label"},[_vm._v("Kayıp/Kaçak İşlem Sayısı")]),_c('SvgIconEllipse',{attrs:{"color":'red'}}),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.dashboardData.totalFraudCounts.annotatedFraudCount))])],1),_c('div',{staticClass:"col"},[_c('span',{staticClass:"label"},[_vm._v("Analiz Edilen İşlem Sayısı")]),_c('SvgIconEllipse',{attrs:{"color":'green'}}),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.dashboardData.totalFraudCounts.annotatedCount))])],1),(_vm.getAutoQuery)?_c('div',{staticClass:"col"},[_c('span',{staticClass:"label"},[_vm._v("Şüpheli İşlem Sayısı")]),_c('SvgIconEllipse',{attrs:{"color":'grey'}}),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.dashboardData.totalFraudCounts.modelSuspectCount))])],1):_vm._e(),_c('div',{staticClass:"col"},[_c('span',{staticClass:"label"},[_vm._v("Toplam İşlem Sayısı")]),_c('SvgIconEllipse',{attrs:{"color":'blue'}}),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.dashboardData.totalFraudCounts.totalActivityCount))])],1)])]),_c('div',{staticClass:"right-sidebar"},[_c('div',{staticClass:"mission-details"},[_c('div',{staticClass:"title"},[_vm._v("Görev Detayları")]),_c('div',{staticClass:"indicator"},[_c('el-progress',{attrs:{"type":"circle","color":'#6fcf97',"stroke-width":8,"width":"76","percentage":(
              (_vm.dashboardData.totalFraudCounts.annotatedCount /
                _vm.dashboardData.totalFraudCounts.totalActivityCount) *
              100
            ).toFixed(2)}}),_c('div',{staticClass:"info"},[_c('span',{staticClass:"label"},[_vm._v("Tamamlanan")]),_c('span',{staticClass:"value-success"},[_vm._v(_vm._s(_vm.dashboardData.totalFraudCounts.annotatedCount))])])],1),_c('div',{staticClass:"indicator"},[_c('el-progress',{attrs:{"type":"circle","color":'#e04141',"stroke-width":8,"width":"76","percentage":(
              (_vm.dashboardData.totalFraudCounts.notAnnotatedCount /
                _vm.dashboardData.totalFraudCounts.totalActivityCount) *
              100
            ).toFixed(2)}}),_c('div',{staticClass:"info"},[_c('span',{staticClass:"label"},[_vm._v("Bekleyen")]),_c('span',{staticClass:"value-warning"},[_vm._v(_vm._s(_vm.dashboardData.totalFraudCounts.notAnnotatedCount))])])],1)]),_c('div',{staticClass:"most-fraud"},[_c('div',{staticClass:"title"},[_vm._v("En Çok Yapılan Şüpheli İşlemler")]),_c('div',{staticClass:"row"},[_c('span',{staticClass:"label"},[_vm._v("Fiş İptali")]),_c('el-progress',{staticStyle:{"width":"185px"},attrs:{"format":{ color: '#000' },"color":'#5D3186',"text-inside":true,"stroke-width":33,"percentage":(
              (100 / _vm.dashboardData.activityFraudCounts.CANCEL.total) *
              _vm.dashboardData.activityFraudCounts.CANCEL.fraud
            ).toFixed(2)}})],1),_c('div',{staticClass:"row"},[_c('span',{staticClass:"label"},[_vm._v("Ürün İade")]),_c('el-progress',{staticStyle:{"width":"185px"},attrs:{"color":'#6D399D',"text-inside":true,"stroke-width":33,"percentage":(
              (100 / _vm.dashboardData.activityFraudCounts.RETURN.total) *
              _vm.dashboardData.activityFraudCounts.RETURN.fraud
            )
              .toFixed(2)
              .toString()}})],1),_c('div',{staticClass:"row"},[_c('span',{staticClass:"label"},[_vm._v("Fiyat Sorgula")]),_c('el-progress',{staticStyle:{"width":"185px"},attrs:{"color":'#7C41B3',"text-inside":true,"stroke-width":33,"percentage":(
              (100 / _vm.dashboardData.activityFraudCounts.PRICEQUERY.total) *
              _vm.dashboardData.activityFraudCounts.PRICEQUERY.fraud
            )
              .toFixed(2)
              .toString()}})],1),_c('div',{staticClass:"row"},[_c('span',{staticClass:"label"},[_vm._v("Satış")]),_c('el-progress',{staticStyle:{"width":"185px"},attrs:{"color":'#9B51E0',"text-inside":true,"stroke-width":33,"percentage":(
              (100 / _vm.dashboardData.activityFraudCounts.SALE.total) *
              _vm.dashboardData.activityFraudCounts.SALE.fraud
            ).toFixed(2)}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }