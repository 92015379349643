<template>
  <div class="last-signals-filter-container">
    <div class="component">
      <span>DURUMLAR</span>
      <el-select
        class="sentinel-input"
        style="width: 100%"
        v-model="state"
        collapse-tags
        placeholder="Seçiniz"
        @change="(val) => filtered_data.state.push(val)"
      >
        <el-option
          v-for="item in status_options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="component">
      <span>BAŞLANGIÇ </span>
      <el-date-picker
        class="sentinel-input"
        style="width: 100%"
        v-model="filtered_data.start_time"
        type="date"
        placeholder="Başlangıç tarihi seçiniz"
      >
      </el-date-picker>
    </div>

    <div class="component">
      <span>BİTİŞ </span>
      <el-date-picker
        class="sentinel-input"
        style="width: 100%"
        v-model="filtered_data.finish_time"
        type="date"
        placeholder="Bitiş tarihi seçiniz"
        @change="handleDateTimeChange"
      >
      </el-date-picker>
    </div>
    <div class="component">
      <el-button type="info" class="search-button" @click="handleSearch">
        <SvgIconSearch></SvgIconSearch>
      </el-button>
    </div>
    <div class="component">
      <el-button @click="handleClearFilterData">Temizle</el-button>
    </div>
  </div>
</template>

<script>
import { DEVICE_STATUS } from '@/constant'
import SvgIconSearch from '@/assets/icons/list/svg-icon-search.vue'
import Vue from 'vue'
import { bus } from '@/main.js'
import moment from 'moment'

export default {
  name: 'DeviceDetailsLastSignalsFilter',
  components: { SvgIconSearch },
  data() {
    return {
      state: '',
      signals_code: [],
      filtered_data: {
        start_time: null,
        finish_time: null,
        state: []
      },
      status_options: []
    }
  },
  methods: {
    handleDateTimeChange(val) {
      if (this.filtered_data.start_time > val) {
        Vue.notify({
          text: 'Lütfen bitiş tarihinin başlangıç tarihinden sonra olduğuna emin olunuz !',
          group: 'error-template',
          type: 'error'
        })
        this.filtered_data.finish_time = null
      }
    },
    // onFilteredDataClick() {
    //   this.filtered_data.state = this.signals_code.join()
    //   // 	Object.keys(this.filtered_data).forEach((item) => {
    //   // 		if(Array.isArray(this.filtered_data[item])){
    //   // 			this.filtered_data.signal_code = this.filtered_data[item].join();
    //   // 		}
    //   //   });
    //   bus.$emit('onFilteredEventsData', this.filtered_data)
    // },
    handleSearch() {
      let state = ''
      let where = {}
      console.log(this.filtered_data)
      this.filtered_data.state?.forEach((item) => {
        console.log(item)
        if (item == 'cancel') {
          where = { ...where, activityType: 2 }
        } else if (item == 'sales') {
          where = { ...where, activityType: 0 }
        } else if (item == 'pricequery') {
          where = { ...where, activityType: 3 }
        } else if (item == 'return') {
          where = { ...where, activityType: 1 }
        } else if (item == 'fraud') {
          where = { ...where, 'adminAnnotation.annotationType': 1 }
        } else if (item == 'non_fraud') {
          where = { ...where, 'adminAnnotation.annotationType': 0 }
          // } else if (item == 'predicition') {
          //   where = { ...where, 'modelPrediction.predictionType': 1 }
          // } else if (item == 'non_prediction') {
          //   where = { ...where, 'modelPrediction.predictionType': 0 }
        } else if (item == 'completed') {
          where = {
            ...where,
            'adminAnnotation.annotationType': { inq: [0, 1] }
          }
        } else if (item == 'non_completed') {
          where = {
            ...where,
            'adminAnnotation.annotationType': { nin: [0, 1] }
          }
        } else if (item == 'total_price_ascending') {
          this.filtered_data = {
            ...this.filtered_data,
            order: 'totalPrice ASC'
          }
        } else if (item == 'total_price_descending') {
          this.filtered_data = {
            ...this.filtered_data,
            order: 'totalPrice DESC'
          }
        }
      })
      console.log(where)
      if (this.filtered_data.casier) where.casier = this.filtered_data.casier
      if (
        this.filtered_data.start_time != null &&
        this.filtered_data.finish_time != null
      )
        where.date = {
          between: [
            this.filtered_data.start_time.toISOString(),
            moment(this.filtered_data.finish_time)
              .add(1, 'd')
              .add(3, 'h')
              .toISOString()
          ]
        }
      state = this.filtered_data.state.join()
      console.log('Sending Data', {
        ...this.filtered_data,
        where: where
      })
      bus.$emit('onFilteredEventsData', {
        ...this.filtered_data,
        where: where
      })

      this.is_total_count_visible = true
      console.log([
        this.filtered_data.start_time.toISOString(),
        this.filtered_data.finish_time.toISOString()
      ])
      return {
        ...this.filtered_data,
        where: where
      }
    },
    handleClearFilterData() {
      //   Object.keys(this.filtered_data).forEach((item) => {
      //     this.filtered_data[item] = ''
      //   })
      this.filtered_data['state'] = []
      this.state = ''
      this.filtered_data['start_time'] = null
      this.filtered_data['finish_time'] = null
      this.signals_code = []
      //   this.$emit('onFilteredData', {})
      bus.$emit('onFilteredEventsData', this.filtered_data)
    }
  },
  created() {
    this.status_options = [...DEVICE_STATUS]
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';
.last-signals-filter-container {
  display: flex;
  flex-direction: row;
  //   background: rgba(160, 160, 160, 0.1);
  background: $hybrone_header_background_color;

  max-width: 100%;
  border-radius: 10px;
  align-content: left;
  justify-content: flex-start;
  // justify-content: flex-start;
  align-items: center;
  height: 85px;
  //   padding-left: 29px;
  .component {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: self-end;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-right: 10px;
    height: 65px;
    &:nth-child(0) {
      margin-left: 23px;
    }
  }
  //   display: flex;
  //   flex-direction: row;
  //   align-content: left;
  //   align-items: center;
  //   justify-content: flex-start;

  span {
    @extend .sentinel-label;
  }
  input {
    @extend .sentinel-input;
  }
  button {
    margin-top: 15px;
    @extend .sentinel-button;

    span {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      text-align: center;

      /* Gray Dark */

      color: #444444;
    }
  }
  .search-button {
    // @extend .sentinel-button;
    width: 50px;
    height: 50px;
    margin-top: 15px;
    padding: initial;
    // &:hover {
    //   background-color: $hybrone_background_color;
    //   border-color: $hybrone_light_blue;
    // }
  }
}
</style>
