<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30 0.25H2C1.0375 0.25 0.25 1.0375 0.25 2V12.5C0.25 13.4625 1.0375 14.25 2 14.25H30C30.9625 14.25 31.75 13.4625 31.75 12.5V2C31.75 1.0375 30.9625 0.25 30 0.25ZM3.75 10.75H28.25V3.75H3.75V10.75ZM2 17.75H30C30.9625 17.75 31.75 18.5375 31.75 19.5V30C31.75 30.9625 30.9625 31.75 30 31.75H2C1.0375 31.75 0.25 30.9625 0.25 30V19.5C0.25 18.5375 1.0375 17.75 2 17.75ZM28.25 21.25H3.75V28.25H28.25V21.25ZM7.25 27.375C5.815 27.375 4.625 26.2025 4.625 24.75C4.625 23.2975 5.815 22.125 7.25 22.125C8.685 22.125 9.875 23.2975 9.875 24.75C9.875 26.2025 8.7025 27.375 7.25 27.375ZM4.625 7.25C4.625 8.7025 5.815 9.875 7.25 9.875C8.7025 9.875 9.875 8.685 9.875 7.25C9.875 5.815 8.685 4.625 7.25 4.625C5.815 4.625 4.625 5.7975 4.625 7.25Z"
      fill="#2C3357"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgIconList'
}
</script>

<style></style>
