<template>
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="19" cy="19" r="19" fill="#2C3357" />
    <path
      d="M27.4079 12.2856L25.7143 10.5919L18.9998 17.3064L12.2854 10.5919L10.5918 12.2856L17.3062 19L10.5918 25.7144L12.2854 27.408L18.9998 20.6936L25.7143 27.408L27.4079 25.7144L20.6935 19L27.4079 12.2856Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgIconClose'
}
</script>

<style></style>
