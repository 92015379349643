<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30 16.18V6.54L15 0L0 6.54V16.36C0 25.44 6.4 33.94 15 36C16.1 35.74 17.16 35.36 18.2 34.9C20.36 37.98 23.94 40 28 40C34.62 40 40 34.62 40 28C40 22.06 35.68 17.14 30 16.18ZM16 28C16 29.12 16.16 30.22 16.46 31.24C15.98 31.46 15.5 31.68 15 31.84C8.66 29.84 4 23.36 4 16.36V9.16L15 4.36L26 9.16V16.18C20.32 17.14 16 22.06 16 28ZM28 36C23.58 36 20 32.42 20 28C20 23.58 23.58 20 28 20C32.42 20 36 23.58 36 28C36 32.42 32.42 36 28 36Z"
      fill="#A0A0A0"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgIconFraud'
}
</script>

<style></style>
