<template>
  <svg
    width="39"
    height="39"
    viewBox="0 0 39 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.125 4.875H30.875C32.6625 4.875 34.125 6.3375 34.125 8.125V30.875C34.125 32.6625 32.6625 34.125 30.875 34.125H8.125C6.32125 34.125 4.875 32.6625 4.875 30.875V24.375H8.125V30.875H30.875V8.125H8.125V14.625H4.875V8.125C4.875 6.3375 6.32125 4.875 8.125 4.875ZM18.6875 27.625L16.3962 25.3337L20.5887 21.125H4.875V17.875H20.5887L16.3962 13.6663L18.6875 11.375L26.8125 19.5L18.6875 27.625Z"
      fill="#2C3357"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgIconServices'
}
</script>

<style></style>
