<template>
  <svg
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M37.5 20.625H14.6812L25.1625 10.1437L22.5 7.5L7.5 22.5L22.5 37.5L25.1437 34.8562L14.6812 24.375H37.5V20.625Z"
      fill="#2C3357"
    />
  </svg>
</template>

<script>
export default {
  name: 'BackArrow'
}
</script>

<style></style>
