<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30.595 0.3025L30.875 0.25C31.365 0.25 31.75 0.635 31.75 1.125V27.585C31.75 27.9875 31.4875 28.3025 31.12 28.425L21.25 31.75L10.75 28.075L1.405 31.6975L1.125 31.75C0.635 31.75 0.25 31.365 0.25 30.875V4.415C0.25 4.0125 0.5125 3.6975 0.88 3.575L10.75 0.25L21.25 3.925L30.595 0.3025ZM19.5 7.02248L12.5 4.57248V24.9775L19.5 27.4275V7.02248ZM3.75 6.30496L9 4.53746V25.0125L3.75 27.0425V6.30496ZM23 27.4625L28.25 25.695V4.97496L23 7.00496V27.4625Z"
      fill="#2C3357"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgIconMaps'
  //   props: { disabled: { type: Boolean, default: false } }
}
</script>

<style></style>
