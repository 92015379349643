<template>
  <svg
    width="54"
    height="64"
    viewBox="0 0 54 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M53.25 22.625H38.25V0.125H15.75V22.625H0.75L27 48.875L53.25 22.625ZM23.2499 30.125V7.625H30.7499V30.125H35.1374L26.9999 38.2625L18.8624 30.125H23.2499ZM53.25 63.875V56.375H0.75V63.875H53.25Z"
      fill="black"
      fill-opacity="0.54"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgIconSettingDownload'
}
</script>

<style></style>
