<template>
  <el-dialog
    title="Shipping address"
    style="border-top-left-radius: 20px"
    :custom-class="'mypopup'"
    :visible.sync="visibleDialog"
    width="1200px"
    :before-close="handleClose"
  >
    <div class="popup-container">
      <div class="popup-container-title">
        <div class="popup-container-title-content">
          <span class="label">İŞLEM</span>
          <span class="value">{{
            this.RegisterActivityType[data.activityType]
          }}</span>
        </div>
        <div class="popup-container-title-content">
          <span class="label">FİŞ NO</span>
          <span class="value">{{ data.details.receiptNo || '-' }}</span>
        </div>
        <div class="popup-container-title-content">
          <span class="label">KASA</span>
          <span class="value">{{ data.posCode }}</span>
        </div>
        <div class="popup-container-title-content">
          <span class="label">KASİYER</span>
          <span class="value">{{
            data.cashier != undefined ? data.cashier.fullName : '-'
          }}</span>
        </div>
        <div class="popup-container-title-content">
          <span class="label">ID</span>
          <span class="value">{{ data.storeCode }}</span>
        </div>
        <div class="popup-container-title-content">
          <span class="label">LOKASYON</span>
          <span class="value">{{
            data.cashier != undefined ? data.cashier.storeName : ''
          }}</span>
        </div>
        <div v-if="getAutoQuery" class="popup-container-title-content">
          <span class="label">ŞÜPHELİ İŞLEM DURUMU</span>
          <span class="value">{{
            data.modelPrediction
              ? formatAIPercents(data.modelPrediction.predictionConfidence)
              : '-'
          }}</span>
        </div>
        <div class="popup-container-title-content">
          <span class="label">İŞLEM ZAMANI</span>
          <span class="value">{{ formatDateTime(data.date) }}</span>
        </div>
      </div>
      <div class="popup-container-content">
        <div class="popup-container-content-receipt-detail">
          <span>FİŞ DETAYI</span>
          <div class="popup-container-content-receipt-detail-table">
            <el-table
              :data="data.details.lines"
              style="width: 99%"
              :header-cell-style="{
                fontWeight: 500,
                fontSize: '14px',
                color: '#444444'
              }"
              :row-style="{
                fontWeight: 400,
                fontSize: '14px',
                color: '#444444'
              }"
              height="100%"
            >
              <el-table-column property="productName" label="ÜRÜN" width="300">
                <template slot-scope="scope">
                  <div>{{ `${scope.row.productName}` }}</div>
                  <!-- <br /> -->
                  <div
                    style="
                      color: #b7b7b7;
                      font-size: 12px;
                      font-weight: 300;
                      line-height: 12px;
                    "
                  >
                    {{ `Ürün Kodu : ${scope.row.productCode}` }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column property="vatPercent" label="KDV" width="75">
                <template slot-scope="scope">
                  {{ '%' + scope.row.vatPercent * 1 }}
                </template></el-table-column
              >

              <el-table-column property="totalPrice" label="TUTAR" width="75">
                <template slot-scope="scope">
                  {{ scope.row.totalPrice + '₺' }}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="popup-container-content-receipt-detail-general-sum">
            <div class="popup-container-content-receipt-detail-general-sum-row">
              <span>TOPLAM KDV</span>
              <span>{{ calculateVatTotal().toFixed(2) }}</span>
            </div>
            <div class="popup-container-content-receipt-detail-general-sum-row">
              <span>TOPLAM </span>
              <span>{{ data.totalPrice }}</span>
            </div>
            <hr />
            <div class="popup-container-content-receipt-detail-general-sum-row">
              <span
                class="popup-container-content-receipt-detail-general-sum-row-payment-type"
                >{{
                  data.details.payments && data.details.payments.length > 0
                    ? data.details.payments[0].paymentName != 'Nakit'
                      ? `Kart - ${data.details.payments[0].paymentName}`
                      : data.details.payments[0].paymentName
                    : ''
                }}</span
              >
              <span>{{ data.totalPrice }}</span>
            </div>
          </div>
        </div>
        <div class="popup-container-content-analysis">
          <span>OLAY KAYDI</span>
          <!-- <div class="video" v-if="show_play_image">
            <img
              @click="changeImage(show_play_image)"
              :src="require(`@/assets/play.jpeg`)"
            />
          </div>
          v-else @click="changeImage(show_play_image)" -->
          <div id="videoWrapper" class="video" :ref="videoWrapper"></div>
          <div class="popup-container-content-analysis-description">
            <span>AÇIKLAMA</span>
            <el-input
              type="textarea"
              :rows="6"
              placeholder="Lütfen açıklama giriniz "
              v-model="textarea"
            >
            </el-input>
          </div>
          <div class="popup-container-content-analysis-actions">
            <div
              v-if="this.$route.name != 'Missions'"
              class="popup-container-content-analysis-actions-group"
            >
              <span>KAÇAK</span>
              <el-button size="small" type="success">{{
                data.adminAnnotation != undefined
                  ? data.adminAnnotation.annotationType == undefined
                    ? 'Bekliyor'
                    : data.adminAnnotation.annotationType == 1
                    ? 'Evet'
                    : 'Hayır'
                  : ''
              }}</el-button>
            </div>
            <div
              v-if="this.$route.name != 'Missions'"
              class="popup-container-content-analysis-actions-group"
            >
              <span>PERSONEL</span>
              <el-button size="small">{{
                data.adminAnnotation != undefined
                  ? data.adminAnnotation.sentinelUserName
                  : ''
              }}</el-button>
            </div>
            <div
              class="popup-container-content-analysis-actions-popup-controls"
            >
              <el-button
                v-if="this.$route.name != 'Missions'"
                class="popup-container-content-analysis-actions-popup-controls-update sentinel-button"
                size="medium"
                >DÜZENLE</el-button
              >
              <span
                class="popup-container-content-analysis-actions-popup-controls-label"
                v-else
              >
                Şüpheli İşlem</span
              >
              <el-select
                v-if="this.$route.name != 'StoreDetail'"
                v-model="selectedAnnotation"
                class="popup-container-content-analysis-actions-popup-controls-update sentinel-input"
                style="width: 40%; margin-right: 20px"
                placeholder="Seçiniz"
                clearable
              >
                <el-option
                  v-for="item in status_option"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option
              ></el-select>
              <el-button
                v-if="this.$route.name != 'StoreDetail'"
                class="popup-container-content-analysis-actions-popup-controls-close sentinel-button"
                size="medium"
                @click="onSubmit"
                >KAYDET</el-button
              >
              <!-- <el-button
                class="popup-container-content-analysis-actions-popup-controls-close sentinel-button"
                size="medium"
                @click="() => handleClose()"
                >KAPAT</el-button
              > -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { dateTimeChange, dateTimeChangeAddHours } from '@/utils.js'
import { mapGetters, mapActions } from 'vuex'
import store from '@/store/index.js'
import { aIFraudTreshold } from '@/utils.js'
import endpoints from '@/endpoints'
import JSMpeg from '@cycjimmy/jsmpeg-player'
import moment from 'moment'

import videojs from 'video.js'

// import video1 from '@/assets/videos/58656242.mp4'
// import { videoPlayer } from 'vue-video-player'

export default {
  name: 'SentinelPopup',
  //   components: { videoPlayer },
  data() {
    return {
      player: null,
      show_play_image: true,
      visibleDialog: false,
      options: {
        src: ''
      },
      textarea: '',
      RegisterActivityType: {
        0: 'SATIŞ',
        1: 'İADE',
        2: 'FİŞ İPTAL',
        3: 'FİYAT SORGULAMA'
      },
      status_option: [
        { label: 'Var', value: 1 },
        { label: 'Yok', value: 0 }
      ],
      selectedAnnotation: 0,
      rtspUrlOptions: {
        channel: 1,
        starttime: '2022_07_29_18_03_00',
        endtime: '2022_07_29_18_04_00'
      },
      rtspData: null,
      videoWrapper: null,
      onSubmitFlag: false
    }
  },
  props: {
    dialogTableVisible: { type: Boolean, default: false },
    data: { type: Object, default: () => {} }
  },
  computed: {
    ...mapGetters({
      getAutoQuery: 'auth/getAutoQuery'
    })
  },
  watch: {
    data: function (val) {
      this.rtspData = val.store?.rtspData
      this.formatRtspDateTime(val)
      console.log('Gelen Data', val)
      this.selectedAnnotation = val.adminAnnotation?.annotationType
      this.textarea = val.adminAnnotation?.description
    },
    dialogTableVisible: function (val) {
      val == true ? this.playRtspStream() : ''
      console.log('Watch Popup DAta', val)
      this.visibleDialog = val
    }
  },

  methods: {
    ...mapActions({
      setAnnotationTask: 'shopies/setAnnotationTask'
    }),
    async stopRtspStream(val) {
      let stream = this.$rtsp({
        ...endpoints.stopRtspPlayer
      })
      stream.then((res) => {
        console.log('Stop Status', res.status)
        // this.playRtspStream()
      })
    },
    async playRtspStream() {
      //   let player = null
      const rtspUrl = `rtsp://${this.rtspData.username}:${
        this.rtspData.password
      }@${this.rtspData.ip}:${this.rtspData.port}/cam/playback?channel=${
        this.data.posCode.split('_')[1]
      }&starttime=${this.rtspUrlOptions.starttime}&endtime=${
        this.rtspUrlOptions.endtime
      }`
      //   const rtspUrl = `rtsp://admin:akbank859100@10.221.9.27:554/cam/playback?channel=1&starttime=2022_09_01_09_45_05&endtime=2022_09_01_10_00_14`
      const streamUrl = `ws://localhost:4001`

      let stream = this.$rtsp({
        ...endpoints.playRtspPlayer,
        data: { url: rtspUrl }
      })
      console.log('Stream', stream)
      if (this.player) {
        this.player.destroy()
      }
      stream.then(() => {
        // player = new JSMpeg.VideoElement(this.videoWrapper, streamUrl, {})
        this.player = new JSMpeg.VideoElement('#videoWrapper', streamUrl, {})
        console.log('Player', this.player)
      })
    },
    changeImage(val) {
      val ? this.stopRtspStream() : this.playRtspStream()
      this.show_play_image = !val
    },
    formatDateTime: (val) => {
      return dateTimeChangeAddHours(val)
      //   return dateTimeChange(val)
    },
    formatAIPercents: (val) => {
      return aIFraudTreshold(val)
    },
    formatRtspDateTime(val) {
      console.log('Val', val)
      if (val.activityType == 3) {
        this.rtspUrlOptions.endtime = moment(val.date)
          .add(-3, 'h')
          .add(5, 's')
          .format('YYYY_MM_DD_HH_mm_ss')
        this.rtspUrlOptions.starttime = moment(val.date)
          .add(-3, 'h')
          .add(-10, 's')
          .format('YYYY_MM_DD_HH_mm_ss')
        // this.rtspUrlOptions.starttime = moment(this.rtspUrlOptions.starttime)
        //   .add(-5, 's')
        //   .format('YYYY_MM_DD_HH_mm_ss')
      } else {
        this.rtspUrlOptions.endtime = moment(val.details.date)
          .add(20, 's')
          .format('YYYY_MM_DD_HH_mm_ss')
        this.rtspUrlOptions.starttime = moment(val.details.startDate)
          .add(-10, 's')
          .format('YYYY_MM_DD_HH_mm_ss')
      }
      console.log('this.rtspUrlOptions', {
        ...this.rtspUrlOptions,
        date: val.date
      })
    },
    calculateVatTotal() {
      let totalVat = 0
      if (this.data.details.lines !== undefined) {
        this.data.details.lines.forEach((line) => {
          totalVat += line.vatTotal
        })
      }
      return totalVat
    },
    async handleClose() {
      if (this.checkValidate(this.textarea) && this.onSubmitFlag) {
        await this.stopRtspStream()
        if (this.player) {
          console.log('Player Stop')
          this.player.stop()
          this.player.destroy()
        }
        this.player = null
        this.visibleDialog = false
        this.$emit('onClose')
        console.log('onClose')
      } else {
        if (this.checkValidate(this.textarea)) {
          //   if(this.onSubmitFlag)
          this.$message({
            type: 'error',
            message: `Kaydet butonuna tıklayınız !`
          })
        } else {
          this.$message({
            type: 'error',
            message: `Lütfen açıklama alanını doldurunuz !`
          })
        }
      }
    },
    checkValidate(val) {
      return !(val == null || val == undefined || val == '')
    },
    onSubmit() {
      if (this.checkValidate(this.textarea)) {
        this.setAnnotationTask({
          annotationType: this.selectedAnnotation,
          description: this.textarea == null ? '' : this.textarea,
          sentinelUserId: store.state.auth.user.data.user.id.toString(),
          sentinelUserName: store.state.auth.user.data.user.username,
          id: this.data.id
        })
        this.onSubmitFlag = true
        this.handleClose()
      } else {
        this.$message({
          type: 'error',
          message: `Lütfen açıklama alanını doldurunuz !`
        })
      }
    }
  },
  mounted() {},

  beforeDestroy() {
    if (this.player) {
      this.stopRtspStream()
      this.player.destroy()
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/style.scss';
.el-dialog {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  padding: 0;
  .el-dialog__header {
    display: none;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: $hybrone_header_background_color;
  }
  .el-dialog__body {
    padding: 0;
  }
}
.popup-container {
  display: flex;
  flex-direction: column;
  &-title {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    height: 100px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: $hybrone_header_background_color;
    &-content {
      //   width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      text-align: center;
      .label {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
      }
      .value {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
      }
    }
  }
  &-content {
    display: flex;
    flex-direction: row;
    padding: 36px;
    height: 650px;
    &-receipt-detail {
      display: flex;
      flex-direction: column;
      width: 45%;
      &-table {
        width: 90%;
        height: 545px;
        left: 207px;
        top: 213px;

        /* Hybrone Blue */

        border: 1px solid #2c3357;
        border-radius: 5px;
      }
      &-general-sum {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        width: 90%;
        gap: 5px;
        hr {
          width: 100%;
        }
        &-row {
          font-weight: 400 !important;
          font-size: 18px !important;
          line-height: 21px !important;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          &-payment-type {
            font-weight: 500 !important;
            font-size: 18px !important;
            line-height: 21px !important;
          }
        }
      }
      span {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
      }
    }
    &-analysis {
      display: flex;
      flex-direction: column;
      width: 55%;
      //   background-color: blue;
      .video {
        width: 100%;
        height: 359px;
        background-color: #2c3357;
      }

      img {
        width: 638px;
        height: 359px;
      }
      //   img:nth-child(2n + 1) {
      //     width: 638px;
      //     height: 359px;
      //   }
      span {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
      }
      &-description {
        margin-top: 30px;
        textarea {
          border: 1px solid #2c3357;
          border-radius: 5px;
        }
      }
      &-actions {
        width: 100%;
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        &-group {
          display: flex;
          flex-direction: column;
          margin-right: 25px;
          span {
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            padding-bottom: 9px;
          }
        }
        &-popup-controls {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          min-width: 100%;
          &-update {
            background-color: #ffffff;
            &:hover {
              background-color: $hybrone_light_blue;
              color: #ffffff !important;
            }
          }
          &-close {
            background-color: #ffffff;
            &:hover {
              background-color: $hybrone_light_blue;
              color: #ffffff !important;
            }
          }
          &-label {
            @extend .sentinel-label;
            // display: flex;
            // flex-direction: column;
            // justify-content: center;
            // align-items: center;
            // align-content: center;
            font-size: 18px !important;
            align-self: center !important;
          }
        }
      }
    }
  }
}
</style>
