<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    :fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.9845 3.13342C17.908 2.26346 16.8519 1.87611 16.2337 2.49438L15.3507 3.37738C15.1533 3.5748 14.8291 3.57804 14.6281 3.38455C14.4195 3.1838 14.4304 2.83793 14.6354 2.63291L15.5039 1.76443C16.1215 1.14682 15.7357 0.0903369 14.8651 0.0136684C12.4986 -0.194402 10.6216 2.00642 11.2492 4.34015L0.499282 15.0901C-0.166374 15.7557 -0.16648 16.8332 0.499282 17.4989C1.16343 18.163 2.24399 18.1631 2.90814 17.4989L13.6553 6.75175C15.9611 7.37874 18.1948 5.52143 17.9845 3.13342Z"
      :fill="currentColor"
    />
    <path
      d="M1.12479 4.12828C1.15 4.17798 1.1829 4.2234 1.22231 4.26281L2.74822 5.78869C2.95415 5.99462 3.288 5.99458 3.49389 5.78869L4.26989 5.01269L5.75187 6.49467L4.16749 8.07901C3.75694 8.4903 3.75729 9.15922 4.16784 9.57013L4.33411 9.7364L9.7418 4.32907L9.57552 4.1628C9.16283 3.75084 8.49696 3.75024 8.0837 4.1628L6.49753 5.74897L5.01555 4.26699L5.79156 3.49099C5.99748 3.2851 5.99748 2.95122 5.79156 2.74532L4.26567 1.21941C4.22584 1.17954 4.17986 1.14636 4.12952 1.12108L2.17917 0.1413C1.97598 0.0392164 1.73041 0.0788338 1.56962 0.239623L0.236969 1.57224C0.0757576 1.73345 0.0364215 1.97997 0.139455 2.18337L1.12479 4.12828Z"
      :fill="currentColor"
    />
    <path
      d="M12.8282 9.07025L9.07471 12.8237L13.3853 17.1354C14.4223 18.1723 16.1011 18.1726 17.1384 17.1354C18.1733 16.1005 18.1733 14.4168 17.1384 13.3823L12.8282 9.07025ZM15.9169 15.9128C15.7123 16.1181 15.3776 16.119 15.1714 15.9128L12.1786 12.92C11.9726 12.714 11.9726 12.3801 12.1786 12.1745C12.3846 11.9685 12.7185 11.9685 12.9241 12.1745L15.9169 15.1672C16.1229 15.3732 16.1229 15.7068 15.9169 15.9128Z"
      :fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgIconServiceRequest',
  props: {
    is_ticket: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentColor() {
      if (!this.is_ticket) {
        return 'rgba(0, 125, 183, 0.17)'
      } else {
        return '#007DB7'
      }
    }
  }
}
</script>

<style></style>
