var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{ref:"detailsTable",staticStyle:{"width":"100%","max-height":"calc(100vh - 180px)","overflow":"auto"},attrs:{"data":_vm.data,"header-cell-style":{ color: '#444444' },"stripe":"","highlight-current-row":"","height":"600px"}},[_c('el-table-column',{attrs:{"property":"id","label":"KANAL","min-width":"35","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('SvgIconFirstChannel',{attrs:{"status":scope.row.status
            ? scope.row.is_active && !scope.row.show_warning
            : null,"device_id":scope.row.channel_id}})]}}])}),_c('el-table-column',{attrs:{"property":"category","label":"KATEGORİ","min-width":"35","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.category.toUpperCase())+" ")]}}])}),_c('el-table-column',{attrs:{"property":"has_video_loss","label":"VİDEO KAYBI","min-width":"35","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('SvgIconChannelStatus',{attrs:{"status":scope.row.status && scope.row.is_active
            ? !scope.row.has_video_loss
            : null}})]}}])}),_c('el-table-column',{attrs:{"property":"HAREKET ALGILAMA","label":"HAREKET ALGILAMA","min-width":"40","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('SvgIconChannelStatus',{attrs:{"status":scope.row.status && scope.row.is_active
            ? !scope.row.motion_detect
            : null}})]}}])}),_c('el-table-column',{attrs:{"property":"has_sabotage","label":"VİDEO SABOTAJ","min-width":"35","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('SvgIconChannelStatus',{attrs:{"status":scope.row.status && scope.row.is_active
            ? !scope.row.has_sabotage
            : null}})]}}])}),_c('el-table-column',{attrs:{"property":"sabotage","label":"SAHNE DEĞİŞİMİ","min-width":"35","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(!scope.row.has_scene_change && scope.row.is_active)?_c('SvgIconChannelStatus',{attrs:{"status":scope.row.status && scope.row.is_active
            ? !scope.row.has_scene_change
            : null}}):_c('ul',{staticClass:"scene_change_list"},[_c('SvgIconChannelStatus',{attrs:{"status":scope.row.status && scope.row.is_active
              ? !scope.row.has_scene_change
              : null}}),(
            scope.row.status && scope.row.is_active
              ? scope.row.has_scene_change
              : null
          )?_c('el-button',{on:{"click":function($event){return _vm.handleSceneChangeResetClick(scope.row)}}},[_vm._v("Reset")]):_vm._e()],1)]}}])}),_c('el-table-column',{attrs:{"property":"trouble","label":"ANLIK GÖRÜNTÜ","min-width":"35","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{staticClass:"button",attrs:{"disabled":!scope.row.status ||
          !scope.row.is_active ||
          !_vm.getPermissions['take_snapshot']},on:{"click":function($event){return _vm.snapshotClick(scope.row.channel_id)}}},[_c('SvgIconSnapshot',{attrs:{"status":scope.row.status && scope.row.is_active}})],1)]}}])}),_c('el-table-column',{attrs:{"property":"by_pass","label":"KAYIT İNDİR","min-width":"35","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{staticClass:"button",attrs:{"disabled":!scope.row.status ||
          !scope.row.is_active ||
          !_vm.getPermissions['download_record']},on:{"click":function($event){return _vm.downloadRecord(scope.row.channel_id)}}},[_c('SvgIconDownload',{attrs:{"status":scope.row.status && scope.row.is_active}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }