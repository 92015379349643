<template>
  <svg
    width="16"
    height="23"
    viewBox="0 0 16 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0569 12.0788C11.226 11.4003 11.9609 10.1447 11.9609 8.771C11.9609 6.66231 10.2454 4.94678 8.13672 4.94678C6.02803 4.94678 4.3125 6.66231 4.3125 8.771C4.3125 10.7475 5.84128 12.4448 7.85944 12.5856C7.89485 11.9327 7.92866 11.4798 7.92927 11.4724C7.97017 10.93 8.62497 10.6802 9.01715 11.0548C9.02338 11.0608 9.46944 11.4874 10.0569 12.0788Z"
      :fill="changeColor"
    />
    <path
      d="M14.7109 0.5H1.5625C0.851711 0.5 0.273438 1.07827 0.273438 1.78906V21.2109C0.273438 21.9217 0.851711 22.5 1.5625 22.5H14.7109C15.4217 22.5 16 21.9217 16 21.2109V1.78906C16 1.07827 15.4217 0.5 14.7109 0.5ZM11.5131 19.1829C10.1233 19.7164 8.55227 19.0235 8.01572 17.6258C7.71227 16.8353 7.77329 14.7617 7.80259 13.8741C5.10089 13.7049 3.02344 11.441 3.02344 8.77106C3.02344 5.95157 5.31724 3.65777 8.13672 3.65777C10.9562 3.65777 13.25 5.95157 13.25 8.77106C13.25 10.499 12.3801 12.0869 10.9754 13.0242C12.5884 14.7265 12.9444 15.3578 13.0702 15.6855C13.6064 17.0825 12.9098 18.6468 11.5131 19.1829Z"
      :fill="changeColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgIconDisk',
  props: {
    status: { type: Boolean, default: null }
  },
  computed: {
    changeColor() {
      if (this.status) {
        return '#6FCF97'
      } else {
        if (this.status == null) return '#DADADA'
        else return '#EB5757'
      }
    }
  }
}
</script>

<style></style>
