<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.5 3.5H11.5V13.5H3.5V3.5ZM21.5 3.5H13.5V9.5H21.5V3.5ZM9.5 11.5V5.50002H5.5V11.5H9.5ZM19.5 7.50002V5.50002H15.5V7.50002H19.5ZM19.5 13.5V19.5H15.5V13.5H19.5ZM9.5 19.5V17.5H5.5V19.5H9.5ZM21.5 11.5H13.5V21.5H21.5V11.5ZM3.5 15.5H11.5V21.5H3.5V15.5Z"
      fill="#2C3357"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgIconAction'
}
</script>

<style></style>
