<template>
  <div
    :class="currentRouteName === 'Maps' ? 'map-filter-device' : 'list-device'"
  >
    <div class="filter">
      <div class="component">
        <span>ARAMA</span>
        <el-input
          class="sentinel-input"
          clearable
          v-model="filtered_data.keywordsearch"
          placeholder="Ürün Kodu, Fiş No, Lokasyon, Kasa"
        ></el-input>
      </div>
      <div class="component">
        <span>KASİYER</span>
        <el-select
          filterable
          v-model="filtered_data.userCode"
          placeholder="Seçiniz"
          @change="handleCasierChange"
          class="sentinel-dropdown"
          clearable
        >
          <el-option
            v-for="item in casier_options"
            placeholder="Seçiniz"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="component">
        <!-- multiple -->
        <span>İŞLEM TİPİ</span>
        <el-select
          v-model="salesType"
          collapse-tags
          placeholder="Seçiniz"
          class="sentinel-dropdown"
          @change="(val) => (filtered_data.salesType = val)"
          clearable
        >
          <el-option
            v-for="item in sales_type_options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="component">
        <!-- multiple -->
        <span>DURUMLAR</span>
        <el-select
          v-model="state"
          collapse-tags
          placeholder="Seçiniz"
          class="sentinel-dropdown"
          @change="(val) => filtered_data.state.push(val)"
          clearable
        >
          <el-option
            v-for="item in status_options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div v-if="['Missions'].includes(this.$route.name)" class="component">
        <span>KULLANICI</span>
        <el-select
          filterable
          v-model="filtered_data.sentinelUserId"
          placeholder="Seçiniz"
          class="sentinel-dropdown"
          clearable
        >
          <el-option
            v-for="item in sentinelUsersOptions"
            placeholder="Seçiniz"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="component">
        <span class="label">TARİH ARALIĞI</span>
        <el-date-picker
          class="sentinel-date-time-input"
          v-model="date_val"
          type="daterange"
          range-separator="-"
          start-placeholder="Başlangıç Tarihi"
          end-placeholder="Bitiş Tarihi"
          clearable
        >
        </el-date-picker>
      </div>

      <div class="component">
        <el-button type="default" @click="handleSearch">
          <SvgIconSearch></SvgIconSearch>
        </el-button>
      </div>
      <div class="component">
        <el-button @click="handleClearFilterData">Temizle</el-button>
      </div>
      <div class="component">
        <span
          v-if="is_total_count_visible && currentRouteName !== 'Maps'"
          class="total_count"
        >
          {{ getTotalRecord }} Sonuç Bulundu</span
        >
      </div>
    </div>
    <div class="actions">
      <div v-if="this.$route.name == 'Missions'" class="component">
        <span>RAPOR</span>
        <el-button
          :disabled="getTotalRecord > 30000"
          @click="handleActionsClick('report')"
        >
          <SvgIconReport></SvgIconReport>
        </el-button>
      </div>

      <div class="component2">
        <el-button
          v-if="this.$route.name == 'MissionsReport'"
          @click="() => this.$router.push('/missions/report')"
        >
          Rapora Git
        </el-button>
        <!-- <el-button v-else @click="() => this.$router.push('/missions')">
          Canlıya Git
        </el-button> -->
      </div>

      <!-- <div v-if="false" class="component">
        <span>İŞLEM</span>
        <el-button :disabled="isSelected" @click="handleActionsClick('action')">
          <SvgIconAction></SvgIconAction>
        </el-button>
      </div>
      <div class="component">
        <span>SERVİS</span>
        <el-button
          :disabled="isSelected || !this.getPermissions['service_create']"
          @click="handleActionsClick('service')"
        >
          <SvgIconService></SvgIconService>
        </el-button>
      </div>
      <div class="component">
        <span>RAPOR</span>
        <el-button
          :disabled="
            isSelected ||
            !this.getPermissions['device_list_status_report_create']
              ? true
              : false
          "
          @click="handleActionsClick('report')"
        >
          <SvgIconReport></SvgIconReport>
        </el-button>
      </div> -->
    </div>
  </div>
</template>

<script>
import SvgIconSearch from '@/assets/icons/list/svg-icon-search.vue'
import SvgIconRefresh from '@/assets/icons/list/svg-icon-refresh.vue'
import SvgIconAction from '@/assets/icons/list/svg-icon-action.vue'
import SvgIconService from '@/assets/icons/list/svg-icon-service.vue'
import SvgIconReport from '@/assets/icons/list/svg-icon-report.vue'
import { DEVICE_TYPES, DEVICE_STATUS, SALES_TYPE } from '@/constant'
import { mapGetters, mapActions } from 'vuex'
import { bus } from '@/main.js'
import axios from 'axios'
import endpoints from '@/endpoints'
import moment from 'moment'
export default {
  name: 'MissionFilter',
  components: {
    SvgIconSearch,
    // SvgIconRefresh,
    // SvgIconAction,
    // SvgIconService,
    SvgIconReport
  },
  computed: {
    ...mapGetters({
      getPermissions: 'auth/getPermissions',
      getTotalRecord: 'pagination/getTotalRecord'
    }),

    isSelected() {
      return !(this.$store.state.dataTable.selectedRows.length > 0)
    },
    currentRouteName() {
      console.log(this.$route.name)
      return this.$route.name
    }
  },
  data() {
    return {
      state: '',
      salesType: '',
      is_total_count_visible: false,
      total_records: 0,
      hardware_type: '',
      filtered_data: {
        search: '',
        state: [],
        salesType: '',
        userCode: '',
        keywordsearch: '',
        sentinelUserId: ''
      },
      status_options: [],
      casier_options: [],
      sales_type_options: [],
      date_val: [],
      sentinelUsersOptions: [],
      salesTypesValues: {
        sales: { activityType: 0 },
        return: { activityType: 1 },
        cancel: { activityType: 2 },
        pricequery: { activityType: 3 },
        hasLineCancel: { hasLineCancel: true }
      }
    }
  },
  methods: {
    ...mapActions({
      getUsersByFiltered: 'auth/getUsersByFiltered'
    }),
    handleClickSelectedDevicesRefresh() {
      bus.$emit('onSelectedDevicesRefresh')
    },
    handleCasierChange(val) {
      console.log('handleCasierChange', val)
    },
    handleClearFilterData() {
      this.filtered_data.search = ''
      this.filtered_data.salesType = ''
      this.filtered_data.state = []
      this.filtered_data.userCode = ''
      this.filtered_data.keywordsearch = ''
      this.filtered_data.sentinelUserId = ''
      this.state = ''
      this.salesType = ''
      this.date_val = []
      this.$emit('onFilteredData', {})
      this.is_total_count_visible = false
    },
    async handleActionsClick(val) {
      let params = this.handleSearch()
      console.log('handleActionsClick', params)
      let currentDate = new Date()

      let filter = {
        // offset: params.offset || 1,
        // limit: params.limit || 10,
        order: 'date DESC',
        where: {
          // ...params.where,
          //   videoUrl: { neq: null },
          date: {
            between: ['2022-03-13T20:55:03.187Z', '2022-03-17T20:55:03.187Z']
          }
          // storeCode: '5688'
          //   userCode: '26365'
        },

        include: ['cashier', 'store']
      }
      if (params.date?.length > 0) {
        // filter.where.date.between = params.date
        filter.where.date.between[0] = moment(params.date[0]).toISOString()
        filter.where.date.between[1] = moment(params.date[1])
          .add(1, 'd')
          .add(3, 'h')
          .toISOString()
      } else delete filter.where.date
      if (params.order) {
        filter.order = params.order
      }
      if (params.userCode) {
        filter.where.userCode = params.userCode
      }
      if (params.keywordsearch) {
        filter.where.keywordsearch = params.keywordsearch
      }
      let shopies = this.$fraud({
        ...endpoints.getRegisterActivityExport,
        params: {
          filter: { ...filter, where: { ...filter.where, ...params.where } }
        }
      })
        //   await axios
        //     .get('http://3.70.144.38:3000//register-activities/export', {
        //       responseType: 'blob',

        //     })
        .then((r) => {
          const url = window.URL.createObjectURL(new Blob([r.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            'Rapor-Listesi-' +
              currentDate.getFullYear() +
              ('0' + (currentDate.getMonth() + 1)).slice(-2) +
              ('0' + currentDate.getDate()).slice(-2) +
              ('0' + currentDate.getHours()).slice(-2) +
              ('0' + currentDate.getMinutes()).slice(-2) +
              ('0' + currentDate.getSeconds()).slice(-2) +
              '.xlsx'
          )
          document.body.appendChild(link)
          link.click()
        })
      //   this.$emit('onActionClick', val)
    },
    handleSearch() {
      let state = ''
      let where = {}
      console.log('Filtered Data', this.filtered_data)
      where = {
        ...where,
        ...this.salesTypesValues[this.filtered_data.salesType]
      }

      this.filtered_data.state?.forEach((item) => {
        console.log('ForEac İtem', item)
        if (item == 'fraud') {
          where = { ...where, 'adminAnnotation.annotationType': 1 }
        } else if (item == 'non_fraud') {
          where = { ...where, 'adminAnnotation.annotationType': 0 }
          // } else if (item == 'predicition') {
          //   where = { ...where, 'modelPrediction.predictionType': 1 }
          // } else if (item == 'non_prediction') {
          //   where = { ...where, 'modelPrediction.predictionType': 0 }
        } else if (item == 'completed') {
          where = {
            ...where,
            'adminAnnotation.annotationType': { inq: [0, 1] }
          }
        } else if (item == 'non_completed') {
          where = {
            ...where,
            'adminAnnotation.annotationType': { nin: [0, 1] }
          }
        } else if (item == 'total_price_ascending') {
          this.filtered_data = {
            ...this.filtered_data,
            order: 'totalPrice ASC'
          }
        } else if (item == 'total_price_descending') {
          this.filtered_data = {
            ...this.filtered_data,
            order: 'totalPrice DESC'
          }
        }
      })
      console.log(this.filtered_data)
      if (this.filtered_data.casier) where.casier = this.filtered_data.casier
      if (this.filtered_data.sentinelUserId)
        where = {
          ...where,
          'adminAnnotation.sentinelUserId': String(
            this.filtered_data.sentinelUserId
          )
        }
      //   if (this.filtered_data.state != undefined)
      //     state = this.filtered_data.state.join()
      console.log(this.date_val)
      this.$emit('onFilteredData', {
        ...this.filtered_data,
        where: where,
        date: this.date_val
      })
      this.is_total_count_visible = true
      return { ...this.filtered_data, where: where, date: this.date_val }
    },
    async handlePriceFilter(val) {
      val.order == 'ascending'
        ? (this.filtered_data.order = 'totalPrice ASC')
        : val.order == 'descending'
        ? (this.filtered_data.order = 'totalPrice DESC')
        : (this.filtered_data.order = 'date DESC')
    }
  },
  created() {
    // this.hardware_type = this.$route.path.split('/')[2].toUpperCase()
    let users = this.getUsersByFiltered({})
    console.log('Mission Filter Users', users)
    users.then((users) => {
      this.sentinelUsersOptions = users.data.data.users.map((user) => {
        return { value: user.id, label: user.username }
      })
    })
  },
  mounted() {
    bus.$on('handlePriceFilter', (val) => this.handlePriceFilter(val))

    console.log('Permissiion', this.getPermissions)
    let casier = []
    // axios.get('http://3.70.144.38:3000/cashiers')

    this.$fraud({
      ...endpoints.getCasiers
    }).then((r) => {
      this.casier_options = r.data.data.result.map((cas) => {
        return { label: cas.fullName.toUpperCase(), value: cas.code }
      })
    })
    this.casier_options = this.casier_options.sort()
    // TODO
    this.status_options = [...DEVICE_STATUS]
    this.sales_type_options = [...SALES_TYPE]
  },
  destroyed() {
    bus.$off('handlePriceFilter')
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/style.scss';

.list-device {
  display: flex;
  flex-direction: row;
  // background: $hybrone_background_color;
  background: $hybrone_header_background_color;
  max-width: 100%;
  border-radius: 10px;
  align-content: left;
  justify-content: space-between;
  // justify-content: flex-start;
  align-items: center;
  height: 85px;
  padding: 12px 20px 12px 36px;
  .component {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: self-end;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-right: 10px;

    &:nth-child(0) {
      margin-left: 230px;

      .sentinel-input {
        width: 275px !important;
      }
    }
    &:nth-child(5) {
      min-height: 100%;
      display: flex !important;
      align-items: flex-end !important;
      align-items: flex-end !important;
      justify-content: flex-end !important;
    }
    .total_count {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      /* identical to box height */

      /* Hybrone Light Blue */
      margin-top: 40px;
      color: #007db7;
    }
  }

  .filter {
    display: flex;
    flex-direction: row;
    align-content: left;
    align-items: center;
    justify-content: flex-start;
    .component {
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: self-end;
      justify-content: space-between;
      flex-wrap: nowrap;
      margin-right: 10px;
      &:nth-child(0) {
        div {
          width: 275px !important;
        }
      }
    }

    span {
      @extend .sentinel-label;
    }
    input {
      @extend .sentinel-input;
    }
    button {
      margin-top: 19px;
      @extend .sentinel-input;

      span {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;

        /* Gray Dark */

        color: #444444;
      }
    }
  }

  .actions {
    margin-top: 18px;
    display: flex;
    flex-direction: row;
    align-content: left;
    align-items: center;
    justify-content: flex-start;
    .component2 {
      margin-top: 15px;
      button {
        height: 50px;
        width: 110px;
        padding: 0px;
        color: #444444;
        span {
          color: #444444;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
        }
        &:hover {
          background-color: $hybrone_light_blue;
          span {
            color: $hybrone_background_color;
          }
        }
      }
    }
    span {
      @extend .sentinel-label;
      margin: 0px;
    }
    button {
      height: 50px;
      width: 50px;
      padding: 0px;
      span {
        display: flex;
        justify-content: center;
      }
      &:hover {
        background-color: $hybrone_light_blue;
        svg {
          path {
            fill: $hybrone_background_color;
          }
        }
      }
      &:focus {
        background-color: $hybrone_light_blue;
        border-color: $hybrone_avatar_background;
        svg {
          path {
            fill: $hybrone_background_color;
          }
        }
      }
      &:disabled {
        background-color: #fff;

        svg {
          path {
            fill: $hybrone_disabled_color;
          }
        }
      }
    }
  }
}
.map-filter-device {
  position: absolute;
  min-width: 93%;
  height: 85px;
  display: flex;
  flex-direction: row;
  align-content: left;
  justify-content: space-between;

  //   left: 150px;
  background: rgba(255, 255, 255, 0.75) !important;
  z-index: 9999;
  //   max-width: 1600px !important;
  padding: 0px;
  padding-left: 23px;
  margin-left: 14px;
  @extend .list-device;
  .filter {
    margin-right: 450px;
  }
}
</style>
