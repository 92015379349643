<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.25 6.5C3.25 5.67 3.92 5 4.75 5C5.58 5 6.25 5.67 6.25 6.5C6.25 7.33 5.58 8 4.75 8C3.92 8 3.25 7.33 3.25 6.5ZM3.25 12.5C3.25 11.67 3.92 11 4.75 11C5.58 11 6.25 11.67 6.25 12.5C6.25 13.33 5.58 14 4.75 14C3.92 14 3.25 13.33 3.25 12.5ZM4.75 17C3.92 17 3.25 17.68 3.25 18.5C3.25 19.32 3.93 20 4.75 20C5.57 20 6.25 19.32 6.25 18.5C6.25 17.68 5.58 17 4.75 17ZM21.75 19.5H7.75V17.5H21.75V19.5ZM7.75 13.5H21.75V11.5H7.75V13.5ZM7.75 7.5V5.5H21.75V7.5H7.75Z"
      fill="#444444"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgIconHistory'
}
</script>

<style></style>
