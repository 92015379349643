<template>
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.33341 6.08333H5.20923C6.68308 4.00192 8.90698 2.83333 11.5001 2.83333C16.2865 2.83333 20.1667 6.71353 20.1667 11.5H22.3334C22.3334 5.51691 17.4832 0.666664 11.5001 0.666664C8.46216 0.666664 5.78561 1.94772 3.91674 4.20954V0.666664H1.75008V8.25H9.33341V6.08333ZM13.6667 16.9166H17.7909C16.3171 18.998 14.0932 20.1666 11.5001 20.1666C6.71361 20.1666 2.83341 16.2864 2.83341 11.4999H0.666748C0.666748 17.483 5.517 22.3332 11.5001 22.3332C14.538 22.3332 17.2145 21.0522 19.0834 18.7904V22.3332H21.2501V14.7499H13.6667V16.9166Z"
      fill="#444444"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgIconRefresh'
}
</script>

<style></style>
