<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="note_add_24px">
      <path
        id="icon/action/note_add_24px"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM13 11H11V14H8V16H11V19H13V16H16V14H13V11ZM6 20H18V9H13V4H6V20Z"
        fill="black"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SvgIconServiceImport'
}
</script>

<style></style>
