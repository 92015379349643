<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.06 13.2075H15.0086L21 19.211L19.211 21L13.2075 15.0086V14.06L12.8834 13.7238C11.5146 14.9005 9.73756 15.6089 7.80446 15.6089C3.494 15.6089 0 12.1149 0 7.80446C0 3.494 3.494 0 7.80446 0C12.1149 0 15.6089 3.494 15.6089 7.80446C15.6089 9.73756 14.9005 11.5146 13.7238 12.8834L14.06 13.2075ZM2.40138 7.80434C2.40138 10.7941 4.81476 13.2074 7.80447 13.2074C10.7942 13.2074 13.2076 10.7941 13.2076 7.80434C13.2076 4.81464 10.7942 2.40126 7.80447 2.40126C4.81476 2.40126 2.40138 4.81464 2.40138 7.80434Z"
      fill="#444444"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgIconSearch'
}
</script>

<style></style>
