<template>
  <h1>Takip Edilen Cihazlar</h1>
</template>

<script>
export default {
  name: 'Tracked'
}
</script>

<style></style>
