<template>
  <svg
    width="42"
    height="38"
    viewBox="0 0 42 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.6249 12.5208L39.4374 3.41667C38.9791 1.54167 37.3541 0.25 35.4582 0.25H6.52073C4.64573 0.25 2.99989 1.5625 2.56239 3.41667L0.374892 12.5208C-0.125108 14.6458 0.333225 16.8125 1.66656 18.5208C1.83323 18.75 2.06239 18.9167 2.24989 19.125V33.5833C2.24989 35.875 4.12489 37.75 6.41656 37.75H35.5832C37.8749 37.75 39.7499 35.875 39.7499 33.5833V19.125C39.9374 18.9375 40.1666 18.75 40.3332 18.5417C41.6666 16.8333 42.1457 14.6458 41.6249 12.5208ZM35.3957 4.39583L37.5832 13.5C37.7916 14.375 37.6041 15.25 37.0624 15.9375C36.7707 16.3125 36.1457 16.9167 35.1041 16.9167C33.8332 16.9167 32.7291 15.8958 32.5832 14.5417L31.3749 4.41667L35.3957 4.39583ZM23.0832 4.41667H27.1666L28.2916 13.8333C28.3957 14.6458 28.1457 15.4583 27.6041 16.0625C27.1457 16.6042 26.4791 16.9167 25.6249 16.9167C24.2291 16.9167 23.0832 15.6875 23.0832 14.1875V4.41667ZM13.6874 13.8333L14.8332 4.41667H18.9166V14.1875C18.9166 15.6875 17.7707 16.9167 16.2291 16.9167C15.5207 16.9167 14.8749 16.6042 14.3749 16.0625C13.8541 15.4583 13.6041 14.6458 13.6874 13.8333ZM4.41656 13.5L6.52073 4.41667H10.6249L9.41656 14.5417C9.24989 15.8958 8.16656 16.9167 6.89572 16.9167C5.87489 16.9167 5.22906 16.3125 4.95823 15.9375C4.39573 15.2708 4.20822 14.375 4.41656 13.5ZM6.41656 33.5833V21.0208C6.58322 21.0417 6.72906 21.0833 6.89572 21.0833C8.70823 21.0833 10.3541 20.3333 11.5624 19.1042C12.8124 20.3542 14.4791 21.0833 16.3749 21.0833C18.1874 21.0833 19.8124 20.3333 21.0207 19.1458C22.2499 20.3333 23.9166 21.0833 25.7916 21.0833C27.5416 21.0833 29.2082 20.3542 30.4582 19.1042C31.6666 20.3333 33.3124 21.0833 35.1249 21.0833C35.2916 21.0833 35.4374 21.0417 35.6041 21.0208V33.5833H6.41656Z"
      fill="#007DB7"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgIconStore'
}
</script>

<style></style>
