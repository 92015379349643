<template>
  <svg
    width="39"
    height="39"
    viewBox="0 0 39 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23 8.43334H25.3V10.35H20.3167V5.75C20.3167 4.69146 19.4585 3.83333 18.4 3.83333H12.2667C11.2081 3.83333 10.35 4.69146 10.35 5.75V10.35H7.28334C6.2248 10.35 5.36667 11.2081 5.36667 12.2667V21.5196C5.26681 21.6017 5.17466 21.6944 5.09201 21.7969L0.455324 27.5469C0.00582681 28.1043 0 28.8359 0 29.5167V36.4167C0 37.4751 0.85813 38.3333 1.91667 38.3333H36.4167C37.4751 38.3333 38.3333 37.4751 38.3333 36.4167V29.5167C38.3333 28.8412 38.3004 28.0873 37.8565 27.5413L33.1843 21.7913C33.1176 21.7092 33.0447 21.6334 32.9667 21.5644V12.2667C32.9667 11.2081 32.1085 10.35 31.05 10.35H28.3667V8.43334H30.6667C31.7252 8.43334 32.5833 7.57521 32.5833 6.51667V1.91667C32.5833 0.85813 31.7252 0 30.6667 0H23C21.9415 0 21.0833 0.85813 21.0833 1.91667V6.51667C21.0833 7.57521 21.9415 8.43334 23 8.43334ZM24.15 5.36667V3.06667H29.5167V5.36667H24.15ZM20.3167 16.4833V13.4167H29.9V21.0833H8.43334V13.4167H10.35V16.4833C9.50318 16.4833 8.81667 17.1698 8.81667 18.0167C8.81667 18.8635 9.50318 19.55 10.35 19.55H20.3167C21.1635 19.55 21.85 18.8635 21.85 18.0167C21.85 17.1698 21.1635 16.4833 20.3167 16.4833ZM17.25 16.4833H13.4167V6.9H17.25V16.4833ZM7.13399 24.15L4.35198 27.6H33.9528L31.1494 24.15H7.13399ZM3.06667 30.6667V35.2667H35.2667V30.6667H3.06667Z"
      fill="#6FCF97"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgIconCase'
}
</script>

<style></style>
