<template>
  <div><Login></Login></div>
</template>
<script>
import Login from '@/components/login/Login.vue'

export default {
  name: 'Auth',
  components: { Login }
}
</script>
<style></style>
