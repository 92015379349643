var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"progress-container"},[_c('div',{staticClass:"right"},[_c('span',[_vm._v(_vm._s(_vm.data.total && _vm.data.fraud ? ("%" + (((100 / _vm.data.total) * _vm.data.fraud).toFixed(2))) : "-"))])]),_c('div',{staticClass:"left"},[_c('el-progress',{attrs:{"percentage":(100 / _vm.data.total) * _vm.data.fraud < 1 &&
        !isNaN((100 / _vm.data.total) * _vm.data.fraud)
          ? 1
          : (100 / _vm.data.total) * _vm.data.fraud,"color":_vm.errorColors,"format":_vm.format}}),_c('el-progress',{attrs:{"percentage":(100 / _vm.data.total) * _vm.data.analysis < 1 &&
        !isNaN((100 / _vm.data.total) * _vm.data.analysis)
          ? 1
          : (100 / _vm.data.total) * _vm.data.analysis,"color":_vm.customColorMethod('success'),"format":_vm.format}}),_c('el-progress',{attrs:{"percentage":(100 / _vm.data.total) * _vm.data.suspicion < 1 &&
        !isNaN((100 / _vm.data.total) * _vm.data.suspicion)
          ? 1
          : (100 / _vm.data.total) * _vm.data.suspicion,"color":_vm.customColorMethod('suspicion'),"format":_vm.format}}),_c('el-progress',{attrs:{"percentage":(100 / _vm.data.total) * _vm.data.total < 1 &&
        !isNaN((100 / _vm.data.total) * _vm.data.total)
          ? 1
          : (100 / _vm.data.total) * _vm.data.total,"color":_vm.customColorMethod('total'),"format":_vm.format}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }