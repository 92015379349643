<template>
  <svg
    width="32"
    height="31"
    viewBox="0 0 32 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.81818 1.40909C5.81818 0.634091 5.16364 0 4.36364 0C3.56364 0 2.90909 0.634091 2.90909 1.40909V7.04545H0V21.1364C0 22.9682 1.22182 24.5182 2.90909 25.11V31H5.81818V25.11C7.50545 24.5182 8.72727 22.9682 8.72727 21.1364V7.04545H5.81818V1.40909ZM4.36362 22.5455C3.56362 22.5455 2.90908 21.9114 2.90908 21.1364V18.3182H5.81817V21.1364C5.81817 21.9114 5.16362 22.5455 4.36362 22.5455ZM2.90908 9.86366V15.5H5.81817V9.86366H2.90908ZM17.4545 1.40909C17.4545 0.634091 16.8 0 16 0C15.2 0 14.5455 0.634091 14.5455 1.40909V7.04545H11.6364V21.1364C11.6364 22.9682 12.8582 24.5182 14.5455 25.11V31H17.4545V25.11C19.1418 24.5182 20.3636 22.9682 20.3636 21.1364V7.04545H17.4545V1.40909ZM14.5454 21.1364C14.5454 21.9114 15.2 22.5455 16 22.5455C16.8 22.5455 17.4545 21.9114 17.4545 21.1364V18.3182H14.5454V21.1364ZM14.5454 15.5V9.86366H17.4545V15.5H14.5454ZM29.0909 1.40909V7.04545H32V21.1364C32 22.9682 30.7782 24.5182 29.0909 25.11V31H26.1818V25.11C24.4945 24.5182 23.2727 22.9682 23.2727 21.1364V7.04545H26.1818V1.40909C26.1818 0.634091 26.8364 0 27.6364 0C28.4364 0 29.0909 0.634091 29.0909 1.40909ZM27.6364 22.5455C26.8364 22.5455 26.1818 21.9114 26.1818 21.1364V18.3182H29.0909V21.1364C29.0909 21.9114 28.4364 22.5455 27.6364 22.5455ZM26.1818 9.86366V15.5H29.0909V9.86366H26.1818Z"
      fill="#2C3357"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgIconServices'
}
</script>

<style></style>
