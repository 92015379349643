<template>
  <svg
    width="17"
    height="19"
    viewBox="0 0 17 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.9582 10.75H9.70817V18.25H7.2915V10.75H0.0415039V8.25H7.2915V0.75H9.70817V8.25H16.9582V10.75Z"
      fill="#444444"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgIconAdd'
}
</script>

<style></style>
