<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="menu_24px">
      <path
        id="icon/navigation/menu_24px"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3 8V6H21V8H3ZM3 13H21V11H3V13ZM3 18H21V16H3V18Z"
        fill="#444444"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SvgIconPerson'
}
</script>

<style></style>
