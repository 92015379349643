<template>
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.935 7H33.25C35.175 7 36.75 8.575 36.75 10.5V35C36.75 36.925 35.175 38.5 33.25 38.5H8.75C8.505 38.5 8.2775 38.4825 8.05 38.4475C7.3675 38.3075 6.755 37.9575 6.2825 37.485C5.9675 37.1525 5.705 36.785 5.53 36.365C5.355 35.945 5.25 35.4725 5.25 35V10.5C5.25 10.01 5.355 9.555 5.53 9.1525C5.705 8.7325 5.9675 8.3475 6.2825 8.0325C6.755 7.56 7.3675 7.21 8.05 7.07C8.2775 7.0175 8.505 7 8.75 7H16.065C16.8 4.97 18.725 3.5 21 3.5C23.275 3.5 25.2 4.97 25.935 7ZM12.25 17.5V14H29.75V17.5H12.25ZM29.75 24.5V21H12.25V24.5H29.75ZM24.5 28H12.25V31.5H24.5V28ZM21 6.5625C21.7175 6.5625 22.3125 7.1575 22.3125 7.875C22.3125 8.5925 21.7175 9.1875 21 9.1875C20.2825 9.1875 19.6875 8.5925 19.6875 7.875C19.6875 7.1575 20.2825 6.5625 21 6.5625ZM8.75 35H33.25V10.5H8.75V35Z"
      fill="#2C3357"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgIconLogout'
}
</script>

<style></style>
