<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.9274 11.0931L13.9174 2.08312C13.1674 1.33312 11.8474 1.32312 11.0874 2.08312L2.08744 11.0831C1.30744 11.8631 1.30744 13.1231 2.08744 13.9031L11.0874 22.9031C11.4774 23.2931 11.9874 23.4831 12.4974 23.4831C13.0074 23.4831 13.5174 23.2931 13.9074 22.9031L22.8974 13.9131C23.6874 13.1531 23.6974 11.8931 22.9274 11.0931ZM12.5074 21.4931L3.50744 12.4931L12.5074 3.49312L21.5074 12.4931L12.5074 21.4931ZM8.49744 15.5031V11.5031C8.49744 10.9531 8.94744 10.5031 9.49744 10.5031H14.4974V8.00312L17.9974 11.5031L14.4974 15.0031V12.5031H10.4974V15.5031H8.49744Z"
      fill="#444444"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgIconService'
}
</script>

<style></style>
