<template>
  <svg
    width="17"
    height="21"
    viewBox="0 0 17 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.5 0.5H10.5L16.5 6.5V18.5C16.5 19.6 15.6 20.5 14.5 20.5H2.49C1.39 20.5 0.5 19.6 0.5 18.5L0.51 2.5C0.51 1.4 1.4 0.5 2.5 0.5ZM2.5 2.5V18.5H14.5V7.5H9.5V2.5H2.5Z"
      fill="#444444"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgIconReport'
}
</script>

<style></style>
